.ctaContainer {
    display: flex;
    flex-direction: row;
    background-color: #3E000C;
    color: white;
    padding: 50px 0;
    justify-content: center;
    align-items: center; 
    text-align: center;
}

.ctaTitle {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
}

.ctaSubtitle {
    font-size: 24px;
    font-style: italic;
    margin-bottom: 30px;
}

.ctaButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center; 
}

.ctaButton {
    background-color: #C29500;
    color: #f0f0f0;
    border: none;
    padding: 10px 20px;
    font-size: 20px;
    text-transform: uppercase;
    cursor: pointer;
}

