.avisContainer{
    background-color: #3E000C;
    justify-content: center;
    text-align: center;
}
.avisContainer h3{
    font-size: 19px;
    font-style: italic;
}

.avisContainer h2{
    font-size: 52px;
    font-style: bold;
}

.avisContainer .titre{
    color: white;
    font-family: 'Roboto';
}

.avisCard {
    height: 200px;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 10px;
}
.card-footer {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    padding: 10px;
    border-top: 1px solid #ddd;
}