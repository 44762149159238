.flotteComponent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
  }

  .flotteComponent h2{
    font-size: 52px;
  }
  
  .flotteComponent .flotteCard {
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-top: 20px;
  }
  
  .flotteComponent .flotteCard img {
    max-height: 100px;
    object-fit: contain;
  }
  
  .flotteComponent .flotteCard .card-body {
    padding: 20px;
  }
  
  .flotteComponent .flotteCard h3 {
    font-size: 28px;
    font-weight: bold;
  }
  
  .flotteComponent .flotteCard p {
    font-size: 16px;
    color: #666;
  }  