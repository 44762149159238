header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px; 
    background-color: black;
  }
  
  .container button{
    border: none;
    background-color: none;
  }
  
  header img {
    max-width: 100%;
  }
  
  nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  div .navbar-nav{
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
  }
  nav ul li {
    display: inline-block; 
    margin-right: 10px; 
  }
  
  nav ul li a {
    text-decoration: none;
  }

  .nav-item{
    margin-right: 10px;
  }
  
  .contactHeader {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    color: white;
  }
  
  .contactHeader p {
    margin: 0.625rem;
  }
  
  @media (max-width: 575.98px){
    .container{
      display: flex;
      flex-direction: column;
    }
    div .navbar-nav{
      display: flex;
      flex-direction: row;
    }
    #navigation{
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .contactHeader{
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 15px;
    }
  }