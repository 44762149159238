.introContainer {
    background-size: cover;
    background-position: center;
    color: black;
    padding: 50px;
    height: 125vh;
    position: relative; 
}

.imageContainer {
    position: relative; 
}

.imageOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.introContent {
    display: flex;
    justify-content: space-around;
    align-items: center; 
}

.leftContent {
    max-width: 500px;
    z-index: 1;
    padding-right: 20px;
    margin: 10px 25px 25px 25px; /* Marge haut, droite, bas, gauche */
}

.leftContent h3 {
    font-size: 19px;
    font-style: italic;
    margin-bottom: 0px;
}

.leftContent h2 {
    font-size: 50px;
    font-weight: bold;
    margin-top: 0px;
}

.leftContent p {
    font-size: 18px;
}

.leftContent span{
    font-weight: 700;
}

.leftContent li {
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    font-weight: bold;
}

.rightContent {
    z-index: 1;
    max-width: 500px;
    height: auto;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 50px;
    color: black;
    overflow-y: auto;
}

form {
    display: flex;
    flex-direction: column;
}

.passengerLuggageSection {
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color:rgba(255, 255, 255);
    color: black;
    border-radius: 20px;
    padding: 10px;
}
.inputGroup{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.nomTelSection{
    display: flex;
    flex-direction: row;
}

.serviceGammeSection{
    display: flex;
    flex-direction: row;
}

.passengerLuggageSection label{
    margin-top: 10px;
}

.passengerLuggageSection p{
   margin-bottom: 0px ;
}

.passengerLuggageSection input{
    max-width: 50px;
}

.passengersSection {
    flex: 1; 
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
}

.luggageSection {
    flex: 1; 
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.departArrive{
    display: flex;
    flex-direction: row;
}

.inputGroup {
    margin-bottom: 10px;
}

input,
textarea {
    margin-bottom: 10px;
    padding: 8px;
}

.btn-primary {
    padding: 10px 20px;
    background-color: #C29500;
    color: #fff;
    border: none;
    cursor: pointer;
}

.btn:hover {
    color: inherit;
    background-color: inherit; 
    border-color: transparent;
}

/* Media query pour les écrans de taille téléphone (moins de 576px) */
@media (max-width: 575.98px) {
    .introContainer {
        padding: 20px; 
        height: auto;
    }

    .introContent {
        flex-direction: column; 
        text-align: center; 
    }

    .rightContent {
        margin-top: 20px;
        padding: 20px;
    }    

    .leftContent {
        padding-right: 0px;
        margin: 0px;
    }
    
    .leftContent h2 {
        font-size: 40px;
    }
    .passengerLuggageSection {
        padding: 10px;
        font-size: 13.3px;
    }
    .departArrive label{
        font-size: small;
    }
}


@media (max-width: 280px) {
    .introContainer {
        padding: 20px; 
        height: 250vh;
    }
}